import React from 'react'
import { Link } from 'gatsby'
import { ArrowRightIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class FinancingRtoDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody>										
					<DropItem><Link to={`/${intl.locale}/rto`}><span>{intl.formatMessage({ id: "Rent-To-Own" })}</span><ArrowRightIcon /></Link></DropItem>	
					<DropItem><Link to={`/${intl.locale}/financing`}><span>{intl.formatMessage({ id: "Financing" })}</span><ArrowRightIcon /></Link></DropItem>	
				</DropDownBody>
			</DropDown>
		)
	}
}
export default FinancingRtoDropdown