import React from "react"
import styled from "styled-components"
import { PhoneIcon } from "../Icons"
import BreakpointDown from "../Media/BreakpointDown"
import BreakpointUp from "../Media/BreakpointUp"

const CallStickyButton = styled.div`
    border:1px solid #E3E3E3;
    background-color:#fff;
    padding:12px 15px;
    display:flex;
    align-items:center;
    position:fixed;
    box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.15);
    z-index: 10;
    ${BreakpointUp.sm`        
        right:0;
        bottom:30px;
    `}
   
    ${BreakpointDown.sm`        
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
    `}
    a{
        display:flex;
        align-items:center;
    }
    .icon{
        width:38px;
        height:38px;
        display:flex;
        align-items:center;
        justify-content:center;
        background: #6a6a6a;
        background: -moz-linear-gradient(top,  #6a6a6a 0%, #6a6a6a 50%, #211f28 50%, #211f28 100%);
        background: -webkit-linear-gradient(top,  #6a6a6a 0%,#6a6a6a 50%,#211f28 50%,#211f28 100%);
        background: linear-gradient(to bottom,  #6a6a6a 0%,#6a6a6a 50%,#211f28 50%,#211f28 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a6a6a', endColorstr='#211f28',GradientType=0 );
        border: 1px solid #fff;
        color:#fff;	
        box-shadow:0 3px 6px rgba(0,0,0,0.3);
        > svg{
            fill:#fff;
        }
        
        + .text{
            margin-left:8px;
        }
    }
    .text{
        
        small{
            font-size:16px;
            color:#6A6A6A;
            line-height:18px;
            display:block;
        }
        strong{
            color:#211F28;
            font-size:20px;
            line-height:22px;
            font-weight:700;
            font-family: 'Rajdhani', sans-serif;
        }
        ${BreakpointUp.sm`        
            display:none;
        `}
        ${BreakpointUp.xl`        
          display:inline-block;
        `}
    }
    &:hover{
        .icon{
            background: #211f28;
            background: -moz-linear-gradient(top,  #211f28 0%, #211f28 50%, #6a6a6a 50%, #6a6a6a 100%);
            background: -webkit-linear-gradient(top,  #211f28 0%,#211f28 50%,#6a6a6a 50%,#6a6a6a 100%);
            background: linear-gradient(to bottom,  #211f28 0%,#211f28 50%,#6a6a6a 50%,#6a6a6a 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#211f28', endColorstr='#6a6a6a',GradientType=0 );
        }
    }
`


const CallSticky = ({ intl }) => {
  return (
    <CallStickyButton>
        <a href="tel:8009187432">
            <span className="icon">
                <PhoneIcon />
            </span>        
            <span className="text">
                <small>{intl.formatMessage({ id: "Call Us" })}</small>
                <strong>(800) 918-7432</strong>
            </span>
        </a>
    </CallStickyButton>      
  )
}

export default CallSticky
