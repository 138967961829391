import styled from "styled-components"
import BreakpointUp from "../../Media/BreakpointUp"

export const DropDown = styled.div`
	${BreakpointUp.xl`     
		position: absolute;
		background-color:#fff;
		box-shadow: 0px 2px 25px rgba(0,0,0,0.3);
		border:1px solid #E3E3E3;
		max-width: 360px;
		width:100%;
		top:100%;
  `}
	&.sm{
		${BreakpointUp.xl` 
			max-width: 98px;
		`}
	}
`
export const DropDownBody = styled.div`
	padding:20px 20px 0;
	${BreakpointUp.xl` 
		padding:20px;
	`}
`
export const DropDownFooter = styled.div`
	padding:0 20px 30px 20px;
	& a{
		display:block;
		
	}
`
export const DropItem = styled.div`
	background-image: linear-gradient(to right,rgb(112 112 112 / 0.5) 50%,rgba(255,255,255,0) 0%);
    background-position: bottom center;
    background-size: 10px 1px;
	background-repeat: repeat-x;	
	&:last-child{
		background:none;
	}
	& a {
		display: flex;
		align-items: center;
		line-height: 20px;
		color: #6A6A6A;
		font-weight:500;
		padding:10px;
		font-family: 'Rajdhani', sans-serif;
		justify-content: space-between;
		${BreakpointUp.xl`
			padding:12px 0;			
			font-size:16px;
		`}
		& svg {
			fill:#6A6A6A;
			transition: transform 0.3s ease-out;
			width:12px;
		}
		&:hover {
			color: #211F28; 
			svg{				
				transform: translateX(4px);
				fill: #211F28; 
			}  
		} 	
	}
`