import styled from 'styled-components'
import ListArrow from "../../images/BulletIcon.svg"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

export const Section = styled.section`
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};
	background-image: ${props=>props.bgImg};
	background-color: ${props=>props.bgColor};	
	background-size:${props => props.bgSize};
	margin-top: ${props=>props.mt};
	height:${props=>props.ht};
	text-align: ${props=>props.textAlign};	
	border-bottom:1px solid ${props=>props.bdrBottom};
	position: relative;	
	top: ${props=>props.top};
	&:before{
		display:${props => (props.bgBefore ? 'block' : 'none')};
		content:'';		
		position:absolute;
		top: 0;
		background:${props => props.bgBefore};
		background-size:${props => props.bgBeforeSize};
		background-repeat: no-repeat;    	
		width:100%;
		height:100%;
	}
	&:after{
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position:absolute;
		top: 0;
		background:${props => props.bgAfter};
		background-size:${props => props.bgBeforeSize};
		background-repeat: no-repeat;  
		width:100%;
		height:100%;
	}
	${BreakpointDown.xl`
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
		margin-top: ${props=>props.xmt};
		top: ${props=>props.xtop};		
	`}
	${BreakpointDown.md`
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};
		top: ${props=>props.mtop};
	`}
`

Section.defaultProps = {
	top: "0",
	bgImg: "inherit",
	bgColor: "white",
	bgSize: "inherit",
	textAlign: "left",
	bdrBottom:"transparent"
}
export const SectionStatic = styled.div`
	position: relative;	
	background-color: ${props=>props.bgColor};	
	padding-top: ${props=>props.pt};
	padding-left: ${props=>props.pl};
	padding-bottom: ${props=>props.pb};
	padding-right: ${props=>props.pr};
	text-align: ${props=>props.textAlign};	
	border:1px solid ${props=>props.border};
	top: ${props=>props.top};
	z-index:1;
	${BreakpointDown.xl`
		padding:40px;
	`}
	${BreakpointDown.md`
		padding:20px;
	`}
`
SectionStatic.defaultProps = {
	bgColor: "white",
	textAlign: "left",
	top: "0",
	pt: "40px",
	pl: "40px",
	pb: "40px",
	pr: "40px",
	border:"#E3E3E3"
}

export const SectionSubTitle = styled.span`
	display: inline-block;	
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};	
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	position:relative;
	z-index:1;
	text-transform:uppercase;
	&:after{
		display:inline-block;
		position:absolute;
		content:'';
		bottom:0;
		left:0;
		right:0;
		height:8px;
		background-color: rgba(245, 142, 145, 0.5);
		z-index:-1;
	}
`

SectionSubTitle.defaultProps = {
	color: "#211F28",
	maxWidth: "100%",
	mb: "10px"
}

export const SectionPageTitle = styled.h1`
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:auto;
	width:100%;
	${BreakpointDown.lg`
		text-align: ${props=>props.lgTextAlign};
	`}
`

SectionPageTitle.defaultProps = {
	textAlign: "center",
	color: "#211F28",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px"
}

export const SectionTitle = styled.h2`	
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:auto;
	width:100%;

	${BreakpointDown.lg`
		text-align: ${props=>props.lgTextAlign};
	`}
`

SectionTitle.defaultProps = {
	textAlign: "center",
	color: "#211F28",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px"
}

export const SectionLead = styled.div`
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	margin-bottom: ${props=>props.mb};	
	text-align: ${props=>props.textAlign};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width:100%;
	font-size: ${props=>props.fontSize};
	line-height: 30px;
	${BreakpointDown.lg`
		font-size: 16px;
		line-height: 28px;
	`}

`
SectionLead.defaultProps = {
	fontSize: "18px",
	textAlign: "center",
	maxWidth: "100%",
	color: "#6A6A6A",
	ml: "auto",
	mr: "auto",
	mb: "30px",
}

export const SectionDescription = styled.div`
	margin-left: auto;
	margin-right:auto;
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	width:100%;
	& p{
		font-size: 18px;
		line-height: 30px;
		color:#6A6A6A;
		margin-bottom:0;
		${BreakpointDown.lg`
			font-size: 16px;
			line-height: 28px;
		`}
		+ p{
			margin-top:20px;
		}
	}
	${BreakpointDown.lg`
		text-align: ${props=>props.lgTextAlign};
	`}
	
`
SectionDescription.defaultProps = {
	textAlign: "center",
	mb: "30px",
	maxWidth: "100%"	
}


export const BreadCrumb = styled.section`	
	font-size: 11px;	
	line-height: 26px;
	color: #6A6A6A;	
	position:relative;
	z-index:2;
	top: ${props=>props.top};
	margin-bottom: ${props=>props.mb};
	${BreakpointUp.md`
		font-size: 14px;
	`}
	${BreakpointDown.xl`
		top: 75px;
	`}
	& a{
		color: #6A6A6A;	
		display: inline-block;
		vertical-align: middle;
		& svg{
			width: 14px;
			display: inline-block;
			fill:#211F28;
			margin-left:6px;
			${BreakpointUp.md`				
				margin-left:12px;
			`}
		}
		&:hover, &:focus{
			color: #211F28;
			svg{
				fill:#211F28;
			}
		}
	}
	& span {
		display: inline-block;
		padding:0 2px;
		color: #211F28;
		font-weight: 600;
	}
`
BreadCrumb.defaultProps = {
	top: "190px",
	mb: "0px"
}

export const MainPanel = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	.sticky-wrapper {
		position: relative;
		top: 0;
	}
	@media(min-width: 992px){
		margin-right: -20px;
		margin-left: -20px;
		.sticky-wrapper {
			position: -webkit-sticky;  // required for Safari
      position: sticky;
			top: 100px;
			right: 0;
			width: 100%;
		}
		.no-sticky {
			position: relative;
			top: 0;
		}
	}
`
export const LeftPannel = styled.div`
	position: relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
	@media(min-width: 992px){
		flex: 0 0 58.333333%;
    	max-width: 58.333333%;
		padding-left:20px;
		padding-right:20px;
	}
`

export const RightPannel = styled.div`
	position: relative;	
	width:100%;
	padding-left:15px;
	padding-right:15px;
	
	@media(min-width: 992px){
		flex: 0 0 41.666667%;
    	max-width: 41.666667%;
		padding-left:20px;
		padding-right:20px;
	}
`

export const MarkdownContent = styled.div`
	display: ${props=>props.display};
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:0 auto;
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	& figure {
		margin-bottom:80px;
		position:relative;
		
	}
	& h2{
		font-size: 30px;
		line-height: 34px;
		@media (min-width: 768px) {
			font-size: 34px;
			line-height: 44px;
		}
		@media (min-width: 992px) {
			font-size: 44px;
			line-height: 50px;
		}
	}
	& p {
		text-align: ${props=>props.textAlign};
		color: ${props=>props.color};
		margin-bottom:20px;
		font-size: 16px;
		line-height: 26px;
	}
	& a{
		color: #6a6a6a;
    	font-weight: 600;
		&:hover, &:focus{
			color:#000;
		}
	}
	& ul {
		list-style: none;
		margin:0 0 30px;
		padding:0;
		& li {
			padding-left:20px;
			vertical-align: text-top;
			color:#6A6A6A;
			margin:0;
			line-height: 26px;
			+ li{
				margin-top:15px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
		}
	}

	+ .pagination-nav{
		.text{
			display:none;	
		}
	}
`

MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0",
	display: 'block',
}

export const Badge = styled.div`  
	display: inline-flex;
	align-items: flex-end;
	position:absolute;
	z-index:1;
	border-right: 15px solid transparent;
	height: 0;
	min-width:92px;
	top:${props=>props.top};
	right:${props=>props.right};
	bottom:${props=>props.bottom};
	left:${props=>props.left};
	color:${props=>props.color};
	border-top: ${props=>props.bdrWidth} solid ${props=>props.bdrColor};
	font-size:${props=>props.fontSize};	
	line-height: ${props=>props.lineHeight};
	strong{
		display: block;    
		white-space: nowrap;
		padding:4px 12px;
		position:relative;	
		font-weight:500;
	}
`
Badge.defaultProps = {
	color:"#fff",
	bdrColor:"#000",
	bdrWidth:"26px",
	top:"0",
	right:"auto",
	bottom:"auto",
	left:"0",
	fontSize:"14px",
	lineHeight:"18px"

}

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	margin: ${props=>props.my} 0;
	border-bottom: 1px solid ${props=>props.borderColor};
`
Divider.defaultProps = {
	my: "0px",
	borderColor: "rgba(2, 3, 4, 0.1)"
}

export const BaseButton = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: 'Rajdhani', sans-serif;		
	text-align:center;
	padding:6px 16px;
	font-size:18px;
	font-weight:700;
	position:relative;	
	border:1px solid transparent;	
	transition: all .4s ease-in-out;	
	> .text{
		line-height: 26px;
		display: inline-flex;
		align-items: center;
		text-transform: uppercase;
		+ .icon{
			margin-left:5px;
		}
	}
	> .icon{
		height: 26px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		+ .text{
			margin-left:5px;
		}	
		&:empty {
			display: none;
		}
	}
`

export const BaseButtonIcon = styled(BaseButton)`	
	> .icon{		
		position:absolute;
		left:-12px;		
		width:24px;
		height:24px;
		border:1px solid transparent;
		> svg{
			height:16px;
		}
		+ .text{
			margin-left:12px;
		}
	}
`

export const ExploreMore = styled.div`
	display:block;
	text-align:center;
	margin-top:${props=>props.mt};
	margin-left:15px;
	margin-right:15px;
	margin-bottom:${props=>props.mb};
`
ExploreMore.defaultProps = {
	mt: "0px",
	mb: "0px"
}



export const FormTwoCol = styled.div`
    max-width:1120px;
    width:100%;
	margin:0 auto;
	border:1px solid #E3E3E3;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
	padding:30px;
	${BreakpointUp.md`
		padding:40px 80px;		
	`} 
	${BreakpointUp.lg`
		padding:75px 170px;		
	`} 

    form{
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        text-align: left;
    }
    .col{
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        ${BreakpointUp.md`
            flex: 0 0 50%;
            max-width: 50%;
		`} 
		&.col-full{
			flex: 0 0 100%;
    		max-width: 100%;
		}
	}	
	
    .form-textarea{
        ${BreakpointUp.md`
            flex: 0 0 100%;
            max-width: 100%;
        `}
    }
    .form-action{
        width: 100%;
    }
`
export const FormControlLabel = styled.div`
	&.active {
		font-size: 75%;
		transform: translate3d(0, -85%, 0);
		opacity: 1;
		color: #6A6A6A;
		font-weight:500;
		background-color:#fff;
		z-index: 3;
	}
`