import React from 'react'
import styled from 'styled-components'

const FormBtn = styled.button`		
	background: #f4f4f4;
	background: -moz-linear-gradient(top,  #f4f4f4 0%, #f4f4f4 50%, #dfdfdf 50%, #dfdfdf 100%);
	background: -webkit-linear-gradient(top,  #f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
	background: linear-gradient(to bottom,#f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#dfdfdf',GradientType=0 );
	border: 1px solid #000;
	color:#211F28;	
	box-shadow:0 3px 6px rgba(0,0,0,0.3);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: 'Rajdhani', sans-serif;		
	text-align:center;
	padding:6px 16px;
	font-size:18px;
	font-weight:700;
	position:relative;	
	border:1px solid transparent;	
	transition: all .4s ease-in-out;	
	> .text{
		line-height: 26px;
		display: inline-flex;
		align-items: center;
		text-transform: uppercase;
		+ .icon{
			margin-left:5px;
		}
	}
	> .icon{
		height: 26px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		+ .text{
			margin-left:5px;
		}	
		&:empty {
			display: none;
		}
		> svg{
			fill:#211F28;
		}
	}	
	&:focus{
		outline:none;
	}
	&:hover{
		background: #dfdfdf;
		background: -moz-linear-gradient(top,  #dfdfdf 0%, #dfdfdf 50%, #f4f4f4 50%, #f4f4f4 100%);
		background: -webkit-linear-gradient(top,  #dfdfdf 0%,#dfdfdf 50%,#f4f4f4 50%,#f4f4f4 100%);
		background: linear-gradient(to bottom,  #dfdfdf 0%,#dfdfdf 50%,#f4f4f4 50%,#f4f4f4 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdfdf', endColorstr='#f9f9f9',GradientType=0 );
	}
`

const FormButton = (props) => {
	const { icon, text } = props;
	return(
		<FormBtn className='btn'>
			<span className='icon'>{icon}</span><span className='text'>{text}</span>
		</FormBtn>
	)
}
export default FormButton