import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import { ArrowRightIcon, ViewIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody, DropDownFooter } from "./tags-component"

class GaragesDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">		
				<DropDownBody>		
					<DropItem><Link to={`/${intl.locale}/vertical-roof-garages`}><span>{intl.formatMessage({ id: "Vertical Roof Garages" })}</span><ArrowRightIcon /></Link></DropItem>								
					<DropItem><Link to={`/${intl.locale}/a-frame-roof-garages`}><span>{intl.formatMessage({ id: "A-Frame Roof Garages" })}</span><ArrowRightIcon /></Link></DropItem>				
					<DropItem><Link to={`/${intl.locale}/regular-roof-garages`}><span>{intl.formatMessage({ id: "Regular Roof Garages" })}</span><ArrowRightIcon /></Link></DropItem>												
				</DropDownBody>		
				<DropDownFooter>
					<ViewLink to={`/${intl.locale}/metal-garages`}>						
						<span className="icon"><ViewIcon /></span>
						<span className="text">{intl.formatMessage({ id: "All Metal Garages" })}</span>
					</ViewLink>
				</DropDownFooter>			
			</DropDown>
		)
	}
}

export default GaragesDropdown