import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import { ArrowRightIcon, ViewIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody, DropDownFooter } from "./tags-component"

class CarportsDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">
				<DropDownBody>
					<DropItem><Link to={`/${intl.locale}/vertical-roof-carports`}><span>{intl.formatMessage({ id: "Vertical Roof Carports" })}</span><ArrowRightIcon /></Link></DropItem>								
					<DropItem><Link to={`/${intl.locale}/a-frame-roof-carports`}><span>{intl.formatMessage({ id: "A-Frame Roof Carports" })}</span><ArrowRightIcon /></Link></DropItem>				
					<DropItem><Link to={`/${intl.locale}/regular-roof-carports`}><span>{intl.formatMessage({ id: "Regular Roof Carports" })}</span><ArrowRightIcon /></Link></DropItem>														
				</DropDownBody>		
				<DropDownFooter>
					<ViewLink to={`/${intl.locale}/metal-carports`}>
						<span className="icon"><ViewIcon /></span>
						<span className="text">{intl.formatMessage({ id: "All Metal Carports" })}</span>	
					</ViewLink>
				</DropDownFooter>
			</DropDown>
		)
	}
}

export default CarportsDropdown
