import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import { ArrowRightIcon, ViewIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody, DropDownFooter } from "./tags-component"

class CommercialDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody>			
					<DropItem><Link to={`/${intl.locale}/commercial-buildings`}><span>{intl.formatMessage({ id: "Commercial Buildings" })}</span><ArrowRightIcon /></Link></DropItem>						
					<DropItem><Link to={`/${intl.locale}/clear-span-buildings`}><span>{intl.formatMessage({ id: "Clear Span Buildings" })}</span><ArrowRightIcon /></Link></DropItem>	
					<DropItem><Link to={`/${intl.locale}/custom-buildings`}><span>{intl.formatMessage({ id: "Custom Buildings" })}</span><ArrowRightIcon /></Link></DropItem>	
				</DropDownBody>		
				<DropDownFooter>
					<ViewLink to={`/${intl.locale}/metal-buildings`}>
						<span className="icon"><ViewIcon /></span>
						<span className="text">{intl.formatMessage({ id: "View All metal Buildings" })}</span>
					</ViewLink>
				</DropDownFooter>			
			</DropDown>
		)
	}
}

export default CommercialDropdown