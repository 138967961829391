import React from "react"
import styled from "styled-components"
import FormButton from "../Button/FormButton"
import axios from 'axios'
import { navigate } from "gatsby"
import CloseIcon from '../Icons/CloseIcon'
import { ArrowRightIcon } from "../Icons"
import { FormControlLabel } from '../Section'
import BreakpointUp from "../Media/BreakpointUp"
import Image from "../image"
import {
	Modal,
	ModalTitle,
	CloseButton,
	ModalDialog,
	ModalContent,
	ModalBody,
	ModalBackdrop,
} from "../Modal"

const ModalTitles = styled(ModalTitle)`
	padding: 60px 10px 0px;
	${BreakpointUp.md`
		padding: 100px 10px 0px 20px;
	`}
`

const QuoteGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const QuoteLeft = styled.div`
	position: relative;
	width: 100%;
	min-height: 1px;
	background-color:#dce7eb;
    text-align: center;
    display: flex;
    flex-direction: column;
	justify-content: space-between;
	text-align: left;
	
	${BreakpointUp.sm`
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	`}
	h2{		
		font-size: 26px;
		line-height: 30px;		
		padding: 60px 10px 0 10px;
		${BreakpointUp.md`					
			padding: 100px 10px 0 20px;
			font-size: 34px;
			line-height: 44px;
		`}
	}
`
const QuoteRight = styled.div`
	position: relative;
	width: 100%;
	min-height: 1px;
	${BreakpointUp.sm`
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	`}
	padding:60px 20px 30px 20px;
	${BreakpointUp.md`					
		padding:60px 50px;
	`}
`

class QuotePopup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			first_name: '',
			email: '',
			phone_no: '',
			state: '',
			zipcode: '',
			comment: ''
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChange(event) {
		const {id, value} = event.target
		this.setState({
			[id]: value
		})
	}
	handleSubmit(event) {
		event.preventDefault()
		const data = {
			first_name: this.state.first_name,
			email: this.state.email,
			phone_no: this.state.phone_no,
			state: this.state.state,
			zipcode: this.state.zipcode,
			comment: this.state.comment,
		}
		axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
    }).then((res) => {
    		document.body.classList.remove('modal-open')
        this.setState({ first_name: '', email: '', phone_no: '', state: '', zipcode: '', comment: ''});
        navigate(`/${this.props.intl.locale}/thanks`);
      }).catch((error) => {
          alert(error)
      });
	}
	render() {
		const { isVisible, intl } = this.props
		return (
			<>
				<Modal
					className={"modal fade " + (isVisible ? "show" : "")}
					style={{ display: isVisible ? "block" : "none" }}
				>	
					<ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
						<ModalContent className="modal-content">
							<ModalBody className="modal-body p-0">
								<CloseButton className="close" onClick={this.props.onClose}>
									<CloseIcon />
								</CloseButton>
								<QuoteGrid>
									<QuoteLeft>
										<ModalTitles className="modal-title">
											To Request A Quote, Fill Out Our Short Form.
										</ModalTitles>
										<Image name="request-quote-bg.png" alt="request-quote-bg"/>  
									</QuoteLeft>
									<QuoteRight>
										<form  id="quoteform" className="quoteform" onSubmit={this.handleSubmit}>
											<div className="form-group">
												<input type="text" id="first_name" className="form-control" onChange={this.handleChange} required />
												<FormControlLabel className={this.state.first_name!==''?"control-label active":"control-label"}>Full Your Name</FormControlLabel>
											</div>
											<div className="form-group">
												<input type="email" id="email" className="form-control" onChange={this.handleChange} required/>
												<FormControlLabel className={this.state.email!==''?"control-label active":"control-label"}>Email Address</FormControlLabel>
											</div>
											<div className="form-group">
												<input type="tel" id="phone_no" className="form-control" onChange={this.handleChange} pattern="[0-9]{10}" required/>
												<FormControlLabel className={this.state.phone_no!==''?"control-label active":"control-label"}>Phone Number (10 digits)</FormControlLabel>
											</div>
											<div className="form-group">
												<select id="state" className="form-control" onBlur={this.handleChange}>
													<option value="">State</option>
													<option value="AL">Alabama</option>
													<option value="AK">Alaska</option>
													<option value="AZ">Arizona</option>
													<option value="AR">Arkansas</option>
													<option value="CA">California</option>
													<option value="CO">Colorado</option>
													<option value="CT">Connecticut</option>
													<option value="DE">Delaware</option>
													<option value="DC">District Of Columbia</option>
													<option value="FL">Florida</option>
													<option value="GA">Georgia</option>
													<option value="HI">Hawaii</option>
													<option value="ID">Idaho</option>
													<option value="IL">Illinois</option>
													<option value="IN">Indiana</option>
													<option value="IA">Iowa</option>
													<option value="KS">Kansas</option>
													<option value="KY">Kentucky</option>
													<option value="LA">Louisiana</option>
													<option value="ME">Maine</option>
													<option value="MD">Maryland</option>
													<option value="MA">Massachusetts</option>
													<option value="MI">Michigan</option>
													<option value="MN">Minnesota</option>
													<option value="MS">Mississippi</option>
													<option value="MO">Missouri</option>
													<option value="MT">Montana</option>
													<option value="NE">Nebraska</option>
													<option value="NV">Nevada</option>
													<option value="NH">New Hampshire</option>
													<option value="NJ">New Jersey</option>
													<option value="NM">New Mexico</option>
													<option value="NY">New York</option>
													<option value="NC">North Carolina</option>
													<option value="ND">North Dakota</option>
													<option value="OH">Ohio</option>
													<option value="OK">Oklahoma</option>
													<option value="OR">Oregon</option>
													<option value="PA">Pennsylvania</option>
													<option value="RI">Rhode Island</option>
													<option value="SC">South Carolina</option>
													<option value="SD">South Dakota</option>
													<option value="TN">Tennessee</option>
													<option value="TX">Texas</option>
													<option value="UT">Utah</option>
													<option value="VT">Vermont</option>
													<option value="VA">Virginia</option>
													<option value="WA">Washington</option>
													<option value="WV">West Virginia</option>
													<option value="WI">Wisconsin</option>
													<option value="WY">Wyoming</option>
												</select>
											</div>   
											<div className="form-group">
												<input id="zipcode" className="form-control" onChange={this.handleChange} required />
												<FormControlLabel className={this.state.zipcode!==''?"control-label active":"control-label"}>Zip Code</FormControlLabel>
											</div>
											<div className="form-group">
												<textarea id="comment" className="form-control" onChange={this.handleChange} required/>
												<FormControlLabel className={this.state.comment!==''?"control-label active":"control-label"}>Write Your Comment Here…</FormControlLabel>
											</div>
											<div className="form-action">
												<FormButton text={intl.formatMessage({ id: "Submit" })} icon={<ArrowRightIcon />}  />
											</div>				
										</form>
									</QuoteRight>
								</QuoteGrid>
							</ModalBody>
						</ModalContent>
					</ModalDialog>
				</Modal>
				{isVisible && (
					<ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
				)}
			</>
		)
	}
}

export default QuotePopup