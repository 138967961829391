import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../Media/BreakpointUp"

export const ViewLink = styled(Link)`
	display: block;
	padding:10px;
	color:#fff;
	font-family: 'Rajdhani', sans-serif;
	background-color:#211F28;
	font-size:16px;
	font-weight:400;
	text-transform:inherit;
	${BreakpointUp.xl` 
		font-size:18px;
		font-weight:700;
		text-transform:uppercase;
	`}
	& .text{
		line-height: 26px;
		display: inline-block;
		vertical-align: middle;	
		
	}
	& .icon{
		width: 18px;
		height: 18px;		
		display: inline-block;
		vertical-align: middle;	
		text-align:center;
		+ .text{
			margin-left:10px;
		}
		& svg{
			fill:#fff;
		}
	}
`
