import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultBtn = styled(BaseButton)`		
    background: #f4f4f4;
    background: -moz-linear-gradient(top,  #f4f4f4 0%, #f4f4f4 50%, #dfdfdf 50%, #dfdfdf 100%);
    background: -webkit-linear-gradient(top,  #f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
    background: linear-gradient(to bottom,#f4f4f4 0%,#f4f4f4 50%,#dfdfdf 50%,#dfdfdf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#dfdfdf',GradientType=0 );
	border: 1px solid #000;
	color:#211F28;	
	box-shadow:0 3px 6px rgba(0,0,0,0.3);
	& .icon{
		> svg{
			fill:#211F28;
		}
	}
	&:hover{
		background: #dfdfdf;
        background: -moz-linear-gradient(top,  #dfdfdf 0%, #dfdfdf 50%, #f4f4f4 50%, #f4f4f4 100%);
        background: -webkit-linear-gradient(top,  #dfdfdf 0%,#dfdfdf 50%,#f4f4f4 50%,#f4f4f4 100%);
        background: linear-gradient(to bottom,  #dfdfdf 0%,#dfdfdf 50%,#f4f4f4 50%,#f4f4f4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdfdf', endColorstr='#f9f9f9',GradientType=0 );
	
	}
`

const DefaultButton = (props) => {
	const { icon, text } = props;
	return(
		<DefaultBtn className='btn'>
			<span className='icon'>{icon}</span><span className='text'>{text}</span>
		</DefaultBtn>
	)
}

export default DefaultButton