import React from 'react'
import { Link } from 'gatsby'
import { ArrowRightIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class AboutDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody>					
					<DropItem><Link to={`/${intl.locale}/about-us`}><span>{intl.formatMessage({ id: "About Us" })}</span><ArrowRightIcon /></Link></DropItem>
					<DropItem><Link to={`/${intl.locale}/faq`}><span>{intl.formatMessage({ id: "FAQs" })}</span><ArrowRightIcon /></Link></DropItem>
					<DropItem><Link to={`/${intl.locale}/contact-us`}><span>{intl.formatMessage({ id: "Contact Us" })}</span><ArrowRightIcon /></Link></DropItem>
				</DropDownBody>
			</DropDown>
		)
	}
}

export default AboutDropdown