import React from "react"
import styled, { keyframes } from "styled-components";
import BreakpointUp from "../Media/BreakpointUp"

const MarkqueeRightToLeft = keyframes`
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
`;

const Markquee = styled.div`
    max-width:1260px;
    width:100%;
    margin: 0 auto;
    background: rgba(33, 31, 40, 0.6);
    padding: 5px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    z-index:1;
    top:64px;
    ${BreakpointUp.xl`
        top:134px;
    `}
    & a{
        font-weight:600;
        color:#fff;
        &:hover, &:focus{
            color:#fff;
        }
    }
`
const SiteMarkquee = styled.div`
    display: inline-block; 
    padding-left: 100%;
    animation-duration: 50s;
    animation-name: ${MarkqueeRightToLeft};
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    &:hover { 
        animation-play-state: paused; 
    }
`



const Markquees = () => {
  return (
    <Markquee className='markquee'>
        <SiteMarkquee>The new pricing is being updated. Please call at <a href="tel:8009187432">(800) 918-7432</a> for accurate pricing.</SiteMarkquee>
    </Markquee>
  )
}

export default Markquees
