import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import DefaultButton from "../components/Button/DefaultButton"
import PrimaryIconButton from "../components/Button/PrimaryIconButton"
import {
  PhoneIcon,
  ThreeDIcon,
  GetQuoteIcon,
  HamburgerIcon,
  ArrowRightIcon,
} from "../components/Icons"
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"
import Logo from "../images/logo.svg"
import UsFlag from "../images/flag-en.jpg"
import SpainFlag from "../images/flag-spain.jpg"

import CarportsDropdown from "../components/Header/DropDown/carports"
import GaragesDropdown from "../components/Header/DropDown/garages"
import BarnsDropdown from "../components/Header/DropDown/barns"
import CommercialDropdown from "../components/Header/DropDown/commercial"
import FinancingRtoDropdown from "../components/Header/DropDown/financing-rto"
import BuyerDropdown from "../components/Header/DropDown/buyer"
import AboutDropdown from "../components/Header/DropDown/about"
import LanguageDropdown from "../components/Header/DropDown/language"
import QuotePopup from "../components/QuotePopup"
import Markquee from "../components/Marquee"

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  height: 64px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 100%;
    max-width: 1350px;
    background-image: linear-gradient(
      to right,
      #cfcfcf 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
  ${BreakpointUp.xl`
    height:108px;
  `}
`
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const Navbar = styled.div`
  padding: 0;
  width: 100%;
`
const Nav = styled.nav`
  background: #211f28;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 26px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  ${BreakpointDown.xl`  
    justify-content: initial;
    padding: 20px 0;  
    flex-direction: column-reverse;
    max-width: 320px;
    width:100%;
    position: absolute;
    top: 64px;
    height: calc(100vh - 64px);
    z-index: 99;
    transition: all 0.3s ease 0s;
    transform: translate3d(320px,0,0);
    box-shadow: 0 3px 26px rgba(0,0,0,0.2);
    overflow-y: auto;
    right: -40px;
  `}
`

const NavBrand = styled(Link)`
  display: flex;
  align-items: center;
  overflow: hidden;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 15px;
  & img {
    margin-bottom: 0;
    display: block;
  }
  ${BreakpointDown.xl`
    width: 150px;
  `}
`

const TopNav = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -8px;
  padding: 12px 0;
  ${BreakpointUp.xl`    
    padding:18px 0;
  `}
  > a, button {
    margin: 0 8px;
    display: inline-block;

    &.isDesktop {
      ${BreakpointDown.xl`
        display:none;
      `}
    }
  }
  .btn {
    ${BreakpointUp.xl`
      font-size:20px;
    `}
    ${BreakpointDown.xl`
      padding: 4px 8px;
    `}
    .text {
      ${BreakpointDown.xl`
        display:none;
      `}
    }
  }

  & .hamburger {
    display: none;
    background: #000;
    padding: 1px 6px;
    margin: 0 8px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    border: 1px solid #000;
    &:hover {
      background-color: #eb1f24;
    }
    & svg {
      fill: #fff;
      width: 22px;
    }
    ${BreakpointDown.xl`
      display: inline-flex;      
    `}
  }
`

const NavbarNav = styled.div`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
    justify-content: inherit;
  `}
`
const NavItem = styled.div`
  margin: 0;
  &:hover {
    cursor: pointer;
  }
  ${BreakpointDown.xl`        
    width:100%;
  `}
  &.active {
    & .nav-link {
      color: #211f28;
      background-color: #f9f9f9;
    }
  }
  &:last-child,
  &:nth-last-child(2) {
    .dropdown-menu {
      ${BreakpointUp.xl`
        right:0;
        left:auto;
      `}
    }
  }
  &.language {
    background-color: #f9f9f9;
    .nav-link {
      color: #292929;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
      .flag {
        height: 18px;
        width: 25px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        padding-right: 8px;
        position: relative;
        background-image: linear-gradient(
          to top,
          #848484 50%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right center;
        background-size: 1px 6px;
        background-repeat: repeat-y;
        after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          height: 50px;
          width: 2%;
          background-image: linear-gradient(
            to top,
            #cfcfcf 50%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: bottom;
          background-size: 1px 10px;
          background-repeat: repeat-y;
        }
      }
      .caret-arrow {
        display: block;
        transition: all 0.3s ease;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%) rotate(90deg);
        width: 10px;
        height: 10px;
        > svg {
          width: 10px;
        }
      }
    }
  }
`

const NavLink = styled.span`
  display: flex;
  align-items: center;
  font-family: "Rajdhani", sans-serif;
  line-height: 26px;
  font-weight: 600;
  color: #cfcfcf;
  cursor: pointer;
  white-space: nowrap;
  padding: 12px 30px 12px 15px;
  font-size: 16px;
  position: relative;
  @media (min-width: 1366px) {
    font-size: 18px;
  }
  .caret {
    display: block;
    transition: all 0.3s ease;
    width: 2px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    background-color: #cfcfcf;
    &:after {
      background: #cfcfcf;
      content: "";
      position: absolute;
      width: 8px;
      height: 2px;
      left: -3px;
      top: 3px;
    }
  }
  &:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.05);
    > svg {
      fill: #fff;
    }
  }
`
const DropDown = styled.div`
  display: ${props => props.display};
`

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenu: true,
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
      isVisibleQuote: false,
    }
    this.toggleActive = this.toggleActive.bind(this)
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }
  componentWillUnmount() {
    document.body.classList.remove("menu-open")
    document.removeEventListener("mousedown", this.handleClickOutside)
  }
  handleClickOutside = event => {
    if (
      event.target.parentNode.tagName !== "A" &&
      event.target.tagName !== "A"
    ) {
      this.setState({
        isVisibleCarports: false,
        isVisibleGarages: false,
        isVisibleCommercial: false,
        isVisibleBarns: false,
        isVisibleFinanceRto: false,
        isVisibleBuyer: false,
        isVisibleAbout: false,
        isVisibleLanguage: false,
      })
    }
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open")
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open")
    }
  }
  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu,
    })
    document.body.classList.toggle("menu-open", this.state.isMenu)
  }
  showCarportsDropDown() {
    let flag = !this.state.isVisibleCarports
    this.setState({
      isVisibleCarports: flag,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showGaragesDropDown() {
    let flag = !this.state.isVisibleGarages
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: flag,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showCommercialDropDown() {
    let flag = !this.state.isVisibleCommercial
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: flag,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showBarnsDropDown() {
    let flag = !this.state.isVisibleBarns
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: flag,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showFinancingRtoDropDown() {
    let flag = !this.state.isVisibleFinanceRto
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: flag,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showBuyerDropDown() {
    let flag = !this.state.isVisibleBuyer
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: flag,
      isVisibleAbout: false,
      isVisibleLanguage: false,
    })
  }
  showAboutDropDown() {
    let flag = !this.state.isVisibleAbout
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: flag,
      isVisibleLanguage: false,
    })
  }
  showLanguageDropDown() {
    let flag = !this.state.isVisibleLanguage
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleCommercial: false,
      isVisibleBarns: false,
      isVisibleFinanceRto: false,
      isVisibleBuyer: false,
      isVisibleAbout: false,
      isVisibleLanguage: flag,
    })
  }
  render() {
    const {
      isVisibleCarports,
      isVisibleGarages,
      isVisibleCommercial,
      isVisibleBarns,
      isVisibleFinanceRto,
      isVisibleBuyer,
      isVisibleAbout,
      isVisibleLanguage,
      isVisibleQuote,
    } = this.state
    const { intl } = this.props
    return (
      <>
        <HeaderWrapper>
          <div className="container">
            <HeaderRow display={isVisibleQuote ? "none" : "block"}>
              <NavBrand to={`/${intl.locale}/`}>
                <img src={Logo} alt="logo" />
              </NavBrand>
              <Navbar>
                <TopNav>
                  <a className="d-xl-block btn-lg" href="tel:8009187432">
                    <PrimaryIconButton
                      icon={<PhoneIcon />}
                      text="(800) 918-7432"
                    />
                  </a>
                  <a
                    className="btn-lg"
                    href="https://globalcarport.sensei3d.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DefaultButton
                      icon={<ThreeDIcon />}
                      text={intl.formatMessage({ id: "3D Designer" })}
                    />
                  </a>
                  <button
                    aria-label="get a quote"
                    className="btn-lg"
                    onClick={this.showQuote}
                  >
                    <DefaultButton
                      icon={<GetQuoteIcon />}
                      text={intl.formatMessage({ id: "Get a quote" })}
                    />
                  </button>
                  <button
                    aria-label="hamburger"
                    className="hamburger"
                    onClick={this.toggleActive}
                  >
                    <HamburgerIcon />
                  </button>
                </TopNav>
                <Nav className="nav">
                  <NavbarNav>
                    <NavItem onClick={() => this.showCommercialDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Metal Buildings" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        display={isVisibleCommercial ? "block" : "none"}
                      >
                        <CommercialDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showCarportsDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Metal Carports" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown display={isVisibleCarports ? "block" : "none"}>
                        <CarportsDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showGaragesDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Metal Garages" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown display={isVisibleGarages ? "block" : "none"}>
                        <GaragesDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showBarnsDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Metal Barns" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown display={isVisibleBarns ? "block" : "none"}>
                        <BarnsDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showFinancingRtoDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Financing" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown
                        display={isVisibleFinanceRto ? "block" : "none"}
                      >
                        <FinancingRtoDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showBuyerDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "Buyer's Guide" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown display={isVisibleBuyer ? "block" : "none"}>
                        <BuyerDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem onClick={() => this.showAboutDropDown()}>
                      <NavLink className="nav-link">
                        {intl.formatMessage({ id: "About Us" })}
                        <span className="caret"></span>
                      </NavLink>
                      <DropDown display={isVisibleAbout ? "block" : "none"}>
                        <AboutDropdown intl={intl} />
                      </DropDown>
                    </NavItem>
                    <NavItem
                      className="language"
                      onClick={() => this.showLanguageDropDown()}
                    >
                      <NavLink className="nav-link">
                        {intl.locale === "en" ? (
                          <>
                            <span className="flag">
                              <img src={UsFlag} alt="UsFlag" />
                            </span>{" "}
                            EN{" "}
                            <span className="caret-arrow">
                              <ArrowRightIcon />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="flag">
                              <img src={SpainFlag} alt="UsFlag" />
                            </span>{" "}
                            ES{" "}
                            <span className="caret-arrow">
                              <ArrowRightIcon />
                            </span>
                          </>
                        )}
                      </NavLink>
                      <DropDown display={isVisibleLanguage ? "block" : "none"}>
                        <LanguageDropdown />
                      </DropDown>
                    </NavItem>
                  </NavbarNav>
                </Nav>
              </Navbar>
            </HeaderRow>
          </div>
          <QuotePopup
            isVisible={this.state.isVisibleQuote}
            onClose={this.onClosePopup}
            intl={intl}
          />
        </HeaderWrapper>
        <Markquee />
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
