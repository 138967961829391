/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import favicon16 from "../images/favicon.ico"
// import favicon32 from "../images/favicon.ico"
// import favicon64 from "../images/favicon.ico"
// import faviconPng from "../images/favicon.png"

function SEO({ description, lang, meta, title, schemaMarkup }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content:
            process.env.GATSBY_GOOGLE_SITE_VERIFICATION ||
            `ZuWMXUFTdyw5cJ0v6W_1MJqCa_jaHv0WMN8zK7_TLWU`,
        },
      ].concat(meta)}

      // link={[
      //   {
      //     rel: "icon",
      //     type: "image/ico",
      //     sizes: "16x16",
      //     href: `${favicon16}`,
      //   },
      //   {
      //     rel: "icon",
      //     type: "image/ico",
      //     sizes: "32x32",
      //     href: `${favicon32}`,
      //   },
      //   { rel: "icon", type: "image/ico", href: `${favicon64}` },
      //   { rel: "apple-touch-icon", href: `${faviconPng}` },
      //   { rel: "shortcut icon", type: "image/png", href: `${faviconPng}` },
      // ]}
    >
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
