import React from "react"
import styled from "styled-components"
import { FacebookIcon, TwitterIcon, InstagramIcon } from "../Icons"

const SocialIcon = styled.ul`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  margin: 15px 0;
  padding: 0 30px;
  @media (min-width: 1600px) {
    justify-content: center;
    padding: 0;
    margin: 0;
    flex-direction: column;
    height: 100%;
    &:after {
      display: block;
      content: "";
      background-image: linear-gradient(
        to top,
        rgba(249, 249, 249, 0.5) 50%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top center;
      background-size: 1px 9px;
      background-repeat: repeat-y;
      width: 2px;
      height: 33%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
const SocialItem = styled.li`
  list-style: none;
  margin: 0 10px;
  @media (min-width: 1600px) {
    margin: 0 0 15px;
  }
  a {
    text-align: center;
    svg {
      fill: #6a6a6a;
      @media (min-width: 1600px) {
        fill: #f9f9f9;
      }
    }
    &.facebook {
      &:hover {
        svg {
          fill: #4064ad;
        }
      }
    }
    &.twitter {
      &:hover {
        svg {
          fill: #1da1f2;
        }
      }
    }
    &.instagram {
      &:hover {
        svg {
          fill: #e3405f;
        }
      }
    }
  }
`

const ColorBoard = () => {
  return (
    <SocialIcon className="social">
      <SocialItem>
        <a
          className="item-link facebook"
          href="https://www.facebook.com/globalcarport"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
      </SocialItem>
      <SocialItem>
        <a
          className="item-link twitter"
          href="https://twitter.com/GlobalCarport"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon />
        </a>
      </SocialItem>
      <SocialItem>
        <a
          className="item-link instagram"
          href="https://www.instagram.com/globalcarport"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
      </SocialItem>
    </SocialIcon>
  )
}

export default ColorBoard
