import React from "react"
import styled from "styled-components"
import { GlobeIcon, PhoneIcon, EmailIcon } from "../Icons"
import BreakpointDown from "../Media/BreakpointUp"

const ListInfo = styled.ul`
  margin: 0 0 20px;
  ${BreakpointDown.lg`
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  `}
`

const ListItemInfo = styled.li`
  display: flex;
  flex-wrap: nowrap;
  font-family: "Rajdhani", sans-serif;
  color: #211f28;
  font-weight: 600;
  margin-bottom: 0;
  padding: 10px 0;
  ${BreakpointDown.lg`
    flex: 0 0 50%;
    max-width: 50%;
    padding:15px 0;
  `}
  ${BreakpointDown.sm`
    flex: 0 0 100%;
    max-width: 100%;
  `}
  > .icon {
    width: 24px;
    height: 26px;
    display: flex;
    align-items: center;
    & svg {
      fill: #6a6a6a;
    }
    + .text {
      margin-left: 20px;
    }
  }
  .text {
    font-size: 18px;
    line-height: 30px;
  }
  & a {
    display: block;
    color: #211f28;
    font-size: 18px;
    line-height: 30px;
    &:hover {
      color: #000;
    }
  }
  .btn {
    .icon {
      svg {
        width: 16px;
      }
    }
  }
`

const Address = () => {
  return (
    <ListInfo className="list-info">
      <ListItemInfo>
        <span className="icon">
          <GlobeIcon />
        </span>
        <span className="text">
          529 W Independence Ave Jacksonville IL 62650
        </span>
      </ListItemInfo>
      <ListItemInfo>
        <span className="icon">
          <PhoneIcon />
        </span>
        <span className="text">
          <a href="tel:8009187432">(800) 918-7432</a>
        </span>
      </ListItemInfo>
      <ListItemInfo>
        <span className="icon">
          <EmailIcon />
        </span>
        <span className="text">
          <a href="mailto:sales@globalcarport.com">sales@globalcarport.com</a>
        </span>
      </ListItemInfo>
    </ListInfo>
  )
}

export default Address
