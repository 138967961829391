import React from "react"
import styled from "styled-components" 
import { Link } from "gatsby"
import SocialNetwork from "../SocialNetwork"
import { ArrowRightIcon } from "../Icons"
import BulletArrow from "../../images/bullet-arrow.svg"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import Logo from "../../images/logo.svg"
import Address from "../Address"
import CallSticky from "../CallSticky"
import DefaultButton from "../Button/DefaultButton"

const FooterWrapper = styled.footer`
  background-color:#000;     
  ${BreakpointDown.sm`       
    padding-bottom: 70px;
  `}
`
const FooterTop = styled.div`
  padding:0;
`

const MenuGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:30px -15px;
  ${BreakpointUp.lg`
    margin:0 -15px;
  `}
`
const MenuItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding:15px;
	${BreakpointUp.sm`
		flex-basis: 0;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
	`}
	${BreakpointUp.lg`
    padding:80px 15px;
	`}
`

const MenuTitle = styled.div`
  color:#fff;  
  font-weight:600;
  margin-bottom:10px;
`
const MenuList = styled.ul`
  list-style:none;
  margin:0;
  padding:0;
  ${BreakpointDown.sm` 
    columns: 2;
    column-gap: 0;
	`}
`
const MenuItem = styled.li`
  margin-bottom:0;  
  position:relative;
  line-height:24px;
  margin: 5px 0;
  padding: 5px 0 5px 20px;
  &:before {
    content: url(${BulletArrow});    
    position:absolute;
    left:0;
  }
	& a {
    font-size:14px;
    color:#bbb;
    display:inline-block;
    ${BreakpointUp.md`          
      font-size:15px;      
    `}
		&:hover {
			color:#fff;
		}
	}
`

const Copyright = styled.div`
  display: block;
  font-size: 14px;
  line-height: 38px;
  color: #6A6A6A;
  position: relative;
  background-image: linear-gradient(to right,rgba(249, 249, 249, 0.5) 50%, rgba(255,255,255,0) 0%);
  background-position: top center;
  background-size: 9px 1px;
  background-repeat: repeat-x;
  padding:10px 0;
`


const FooterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${BreakpointUp.lg`    
    flex-direction: row-reverse;
  `}
`
const FooterLeft = styled.div`
  width: 100%;
  
  ${BreakpointUp.lg`    
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  `}
`
const FooterRight = styled.div`
  width: 100%;
  background: #fff;
  position:relative;
  ${BreakpointDown.sm`  
    margin-left: 15px;
  `}
  ${BreakpointUp.lg`    
    flex: 0 0 33.333333%;
    max-width: 33.333333%;     
  `}
  &:after{
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    top: -22px;
    height: 25px;
    ${BreakpointUp.sm`  
      top: -44px;
      height: 50px;
    `}
  }
  &:before{
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border-bottom: 22px solid #6A6A6A;
    border-left: 22px solid transparent;
    top: -22px;
    left: -22px;
    ${BreakpointUp.sm`  
      border-bottom: 44px solid #6A6A6A;
      border-left: 44px solid transparent;
      top: -44px;
      left: -44px;
    `}
  }
`
const FooterInfoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CallInfoCol = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 1600px){
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
`
const SocialCol = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 1600px){
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    position: relative;
    right: -75px;
  }  
`
const CallInfo = styled.div`
  padding:0 30px;
  ${BreakpointUp.xl`
    padding:0 45px;
  `}
`
const ButtonToolbar = styled.div`
  margin-bottom:15px;
`
const FooBrand = styled.div`
  margin-bottom:10px;
`


const Footer = ({intl}) => (
  <FooterWrapper>
    <FooterTop>
      <div className="container">      
        <FooterGrid>
          <FooterRight>
            <FooterInfoGrid>
              <CallInfoCol>            
                <CallInfo>          
                  <FooBrand><img src={Logo} alt="logo" /></FooBrand>
                  <Address />
                  <ButtonToolbar>
                    <Link to={`/${intl.locale}/contact-us`} className="btn-lg"><DefaultButton text={intl.formatMessage({ id: "Contact Us" })} icon={<ArrowRightIcon />} /></Link>
                  </ButtonToolbar>  
                </CallInfo>
              </CallInfoCol>
              <SocialCol>
                <SocialNetwork />
              </SocialCol>
            </FooterInfoGrid>                        
          </FooterRight> 
          <FooterLeft>
            <MenuGrid>
              <MenuItemGrid>
                <MenuTitle>{intl.formatMessage({ id: "Our Buildings" })}</MenuTitle>
                <MenuList>
                  <MenuItem><Link to={`/${intl.locale}/metal-barns`}>{intl.formatMessage({ id: "Metal Barns" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/metal-buildings`}>{intl.formatMessage({ id: "Metal Buildings" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/metal-carports`}>{intl.formatMessage({ id: "Metal Carports" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/metal-garages`}>{intl.formatMessage({ id: "Metal Garages" })}</Link></MenuItem>
                </MenuList>
              </MenuItemGrid>

              <MenuItemGrid>
                <MenuTitle>{intl.formatMessage({ id: "Buyer's Guide" })}</MenuTitle>
                <MenuList>
                  <MenuItem><Link to={`/${intl.locale}/purchase-process`}>{intl.formatMessage({ id: "Purchase Process" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/site-preparation`}>{intl.formatMessage({ id: "Site Preparation" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/installation-process`}>{intl.formatMessage({ id: "Installation Process" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/color-options`}>{intl.formatMessage({ id: "Color Options" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/blog`}>{intl.formatMessage({ id: "Articles" })}</Link></MenuItem>                  
                </MenuList>
              </MenuItemGrid>

              <MenuItemGrid>            
                <MenuTitle>{intl.formatMessage({ id: "Quick Links" })}</MenuTitle>
                <MenuList>
                  <MenuItem><Link to={`/${intl.locale}/about-us`}>{intl.formatMessage({ id: "About Us" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/faq`}>{intl.formatMessage({ id: "FAQs" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/contact-us`}>{intl.formatMessage({ id: "Contact Us" })}</Link></MenuItem>                  
                  <MenuItem><Link to={`/${intl.locale}/privacy`}>{intl.formatMessage({ id: "Privacy Policy" })}</Link></MenuItem>
                  <MenuItem><Link to={`/${intl.locale}/sitemap`}>{intl.formatMessage({ id: "Site Map" })}</Link></MenuItem>
                </MenuList>
              </MenuItemGrid>
            </MenuGrid>
            <Copyright>{intl.formatMessage({ id: "Copyright" })} © {new Date().getFullYear()}. {intl.formatMessage({ id: "All Rights Reserved" })}.</Copyright> 
          </FooterLeft>
        </FooterGrid>
      </div>  
    </FooterTop> 
    <CallSticky intl={intl} />
  </FooterWrapper>
)

export default Footer