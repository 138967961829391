import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import { ArrowRightIcon, ViewIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody, DropDownFooter } from "./tags-component"

class BarnsDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">			
				<DropDownBody>		
					<DropItem><Link to={`/${intl.locale}/continuous-roof-barns`}><span>{intl.formatMessage({ id: "Continuous Roof Barns" })}</span><ArrowRightIcon /></Link></DropItem>								
					<DropItem><Link to={`/${intl.locale}/vertical-roof-barns`}><span>{intl.formatMessage({ id: "Vertical Roof Barns" })}</span><ArrowRightIcon /></Link></DropItem>
					<DropItem><Link to={`/${intl.locale}/a-frame-roof-barns`}><span>{intl.formatMessage({ id: "A-Frame Roof Barns" })}</span><ArrowRightIcon /></Link></DropItem>
					<DropItem><Link to={`/${intl.locale}/regular-roof-barns`}><span>{intl.formatMessage({ id: "Regular Roof Barns" })}</span><ArrowRightIcon /></Link></DropItem>
				</DropDownBody>		
				<DropDownFooter>
					<ViewLink to={`/${intl.locale}/metal-barns`}>
						<span className="icon"><ViewIcon /></span>
						<span className="text">{intl.formatMessage({ id: "All Metal Barns" })}</span>
					</ViewLink>
				</DropDownFooter>	
			</DropDown>
		)
	}
}

export default BarnsDropdown