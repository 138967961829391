import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
function Image(props) {
  const imageData = useStaticQuery(
    graphql`
      query imageQuery {
        allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  const image = imageData.allFile.edges.find(img => {
    return img.node.relativePath.includes(props.name)
  })
  return <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} />
}
export default Image
