import React from 'react'
import { v4 } from 'uuid'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { DropDown, DropItem, DropDownBody } from "./tags-component"
import UsFlag  from "../../../images/flag-en.jpg"
import SpainFlag  from "../../../images/flag-spain.jpg"

const languageName = {
	en: "EN",
	es: "ES",
}

const languageFlag = {
	en: UsFlag,
	es: SpainFlag
}
  
const Language = () => (
	<DropDown className="dropdown-menu sm">	
		<DropDownBody className="dropdown-body">
			<IntlContextConsumer>
				{({ languages, language: currentLocale }) =>
				languages.map(language => (
					<DropItem key={v4()}>
						<a
							key={language}
							onClick={() => changeLocale(language)}
							style={{
								color: currentLocale === language ? `#E64A31` : `#342E37`,
								cursor: `pointer`,
							}}
						>
						<span>{languageName[language]}</span><span><img src={languageFlag[language]} alt={`${language}-flga`} /></span>
						</a>
					</DropItem>
				))
				}
			</IntlContextConsumer>
	  	</DropDownBody>
	</DropDown>
  )
  
  export default Language