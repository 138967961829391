import React from 'react'
import { Link } from 'gatsby'
import { ArrowRightIcon } from "../../Icons"
import { DropDown, DropItem, DropDownBody } from "./tags-component"

class BuyerDropdown extends React.Component {
	render(){
		const { intl } = this.props
		return(
			<DropDown className="dropdown-menu">	
				<DropDownBody>								
					<DropItem><Link to={`/${intl.locale}/purchase-process`}><span>{intl.formatMessage({ id: "Purchase Process" })}</span><ArrowRightIcon /></Link></DropItem>					
					<DropItem><Link to={`/${intl.locale}/site-preparation`}><span>{intl.formatMessage({ id: "Site Preparation" })}</span><ArrowRightIcon /></Link></DropItem>						
					<DropItem><Link to={`/${intl.locale}/installation-process`}><span>{intl.formatMessage({ id: "Installation Process" })}</span><ArrowRightIcon /></Link></DropItem>							
					<DropItem><Link to={`/${intl.locale}/color-options`}><span>{intl.formatMessage({ id: "Color Options" })}</span><ArrowRightIcon /></Link></DropItem>
					<DropItem><Link to={`/${intl.locale}/blog`}><span>{intl.formatMessage({ id: "Blog" })}</span><ArrowRightIcon /></Link></DropItem>
				</DropDownBody>
			</DropDown>
		)
	}
}

export default BuyerDropdown